import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { NgbdRatingDecimal } from './rating-decimal';

export class FormInput {
  id: any;
  userid: any;
  desc: any;
  star: any;
  tid: any;
}






@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));

  @ViewChild('modalProject', {
    static: false
  }) modal: any;
  @ViewChild('modalProject1', {
    static: false
  }) modal1: any;

  // dtExportButtonOptions: any = {};
  dtExportButtonOptions = {
    dom: 'Bfrtip',
  };
  formInput: any={};
  formInput2: FormInput;
  form: any;
  rating : number;
  type: any = 'add';
  public isSubmit: boolean;
  formdata: any = {};
  loading = false;
  buttonloading = false;
  statusloading = [];
  activeTab : any;
  albums:any;
  id:any;
  getSantizeUrl:any;
  tname:any;
  tabledata: any = {};
  reviewdata: any ={};
  teacherclass: any ={};
  editdata: any = {};
  countdata: any = {};
  editOtherInfo:any;
  editOtherInfoIcon:any;
  editContact:any;
  editContactIcon:any;
  editProfile:any;
  editProfileIcon:any;
  public options: any = [{
      value: '0',
      label: 'Alabama'
    },
    {
      value: '1',
      label: 'Wyoming'
    },
    {
      value: '2',
      label: 'Coming'
    },
    {
      value: '3',
      label: 'Henry Die'
    },
    {
      value: '4',
      label: 'John Doe'
    }
  ];
  selectedOption = '3';
  public addform = new FormGroup({
    files: this.filesControl
  });
  public uploadedFiles: Array < File > = [];
  public uploadedFiles2: Array < File > = [];
  selectedsite : any; 
  siteoptions: any[];
  constructor(private route: ActivatedRoute,public global: GlobalService, public router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
console.log(this.id);
    this.isSubmit = false;

  }
  addagent(aa) {
    console.log(this.filesControl.value);
    console.log(this.formInput);
    console.log(this.uploadedFiles);

  }
  open(id){

  }
  ngOnInit() {
    this.activeTab = 'profile';
    //this.filesControl.enable();
    //     this.filesControl.valueChanges.subscribe(() => {
    //       console.log(this.filesControl);
    //       if(this.filesControl.value.length == 0) {
    //          this.filesControl.enable();
    //         } else{
    //             this.filesControl.disable();
    //            }
    //     }

    // );

    this.formInput = {
      pid: '',
      lendername: '',
      image: '',
      link: '',
      lid: '',
    };
    this.formInput2 = {
      id: '',
      userid: '',
      desc: '',
      star: '',
      tid: '',

    };

    this.dtExportButtonOptions = {
      dom: 'Bfrtip',
    };
    this.studentreview('yes');
    this.teacherclasses('yes');
    this.lenders('yes');
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
  }
  openupdate(index) {
    //this.editdata = this.tabledata[index];
    this.formInput2 = {
      id: this.tabledata[index].pid,
      userid: this.tabledata[index].name,
      desc: this.tabledata[index].img,
      star: this.tabledata[index].url,
      tid: this.tabledata[index].id,
    };
    console.log(this.editdata);
    this.type = 'update';
    this.modal.show();
  }
teacherclasses(use)
{
  if(use == 'yes')
  {
    
  }
   // this.formdata = this.formInput;
   console.log(this.formdata);
   const token = localStorage.getItem('token');
   const headers = new Headers();

   this.formdata.sid = this.selectedsite;
   const postData = {id:this.id};
   // const httpOptions = {
   //   headers: new HttpHeaders({
   //     'Content-Type': 'application/json',
   //     Authorization: token
   //   })
   // };

   
   this.global.http.post(this.global.server2 + 'classes/teacherclass.php', postData, {})
     .subscribe(data => {
       console.log(data);
       // tslint:disable-next-line: triple-equals
       if (data['status'] == true) {

         this.teacherclass = data['data'];
        // this.countdata = data['count'];
         //this.formdata.mobile=this.countdata.mobile;
       //  this.formdata.email=this.countdata.email;
         console.log(this.teacherclass);
         //this.tname=this.tabledata['name'];
         this.siteoptions = [];
         let i;
         // for (i = 0; i < data['site'].length; i++) {
         //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
         //   if(!this.selectedsite || this.selectedsite== null){
         //     this.selectedsite = data['site'][0].id;

         //   }
         // }
         
         this.loading = false;

       } else {
         //   alert();
         this.loading = false;

       }
     }, error => {
       console.log(error);
       //this.loading = false;

     });
}
studentreview(use)
{
  if(use == 'yes')
  {
    
  }
   // this.formdata = this.formInput;
   console.log(this.formdata);
   const token = localStorage.getItem('token');
   const headers = new Headers();

   this.formdata.sid = this.selectedsite;
   const postData = {id:this.id};
   // const httpOptions = {
   //   headers: new HttpHeaders({
   //     'Content-Type': 'application/json',
   //     Authorization: token
   //   })
   // };

   
   this.global.http.post(this.global.server2 + 'teachers/review.php', postData, {})
     .subscribe(data => {
       console.log(data);
       // tslint:disable-next-line: triple-equals
       if (data['status'] == true) {
        this.rating = data['rating']['Totalstar'];
        //console.log(this.rating);
         this.reviewdata = data['data'];
        // this.countdata = data['count'];
         //this.formdata.mobile=this.countdata.mobile;
       //  this.formdata.email=this.countdata.email;
         console.log(this.reviewdata);
         //this.tname=this.tabledata['name'];
         this.siteoptions = [];
         let i;
         // for (i = 0; i < data['site'].length; i++) {
         //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
         //   if(!this.selectedsite || this.selectedsite== null){
         //     this.selectedsite = data['site'][0].id;

         //   }
         // }
         
         this.loading = false;

       } else {
         //   alert();
         this.loading = false;

       }
     }, error => {
       console.log(error);
       //this.loading = false;

     });
}
  lenders(loading) {

    if(loading=='yes'){
      this.loading = true;
     }

    // this.formdata = this.formInput;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    this.formdata.sid = this.selectedsite;
    const postData = {id:this.id};
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };

    
    this.global.http.post(this.global.server2 + 'teachers/singleteacher.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {

          this.tabledata = data['data'][0];
         // this.countdata = data['count'];
          //this.formdata.mobile=this.countdata.mobile;
        //  this.formdata.email=this.countdata.email;
          console.log(this.tabledata);
          this.tname=this.tabledata['name'];
          this.siteoptions = [];
          let i;
          // for (i = 0; i < data['site'].length; i++) {
          //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
          //     this.selectedsite = data['site'][0].id;

          //   }
          // }
          
          this.loading = false;

        } else {
          //   alert();
          this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }


  addbanner(form: any) {
    console.log(this.formInput);
    console.log(form.valid);
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }
    if (!this.uploadedFiles[0]) {
      this.isSubmit = true;
alert('Select the image');
      return;
    }



    this.buttonloading = true;
    // this.formdata = this.formInput;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();
    this.formInput.sid = this.selectedsite ;
    const postData = this.formInput;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Accept: 'application/json',
    //     Authorization: token
    //   })
    // };
    let formData: FormData = new FormData();
    console.log(this.uploadedFiles);

    const file: File = this.uploadedFiles[0];
    formData.append('file', file, file.name);
    console.log(this.formInput);
    console.log(this.formInput.toString());
    formData.append('data', JSON.stringify(this.formInput));
    formData.append('name', 'ss');



    this.global.http.post(this.global.server + 'banners/addbanner.php', formData, {})
      .subscribe(data => {
        console.log(data['statusmessage']);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal1.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles = [];
          this.global.addToast({
            title: 'Banner added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }


  updatelender(form: any) {
    console.log(this.formInput);
    console.log(form.valid);
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }




    this.buttonloading = true;
    // this.formdata = this.formInput;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formInput;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: token
      })
    };
    let formData: FormData = new FormData();
    console.log(this.uploadedFiles2);
    if (this.uploadedFiles2[0]) {


      const file: File = this.uploadedFiles2[0];
      formData.append('file', file, file.name);
    }
    console.log(this.formInput);
    console.log(this.formInput.toString());
    formData.append('data', JSON.stringify(this.formInput2));

    this.global.http.post(this.global.server + 'banners/updatebanner.php', formData, httpOptions)
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles2 = [];
          this.global.addToast({
            title: 'Agent added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }




  delete(id) {


    this.buttonloading = true;
    this.formdata.id = id;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server + 'banners/delete.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Deleted successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }
  delete2(id) {


    this.buttonloading = true;
    this.formdata.id = id;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server + 'banners/delete.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Deleted successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }
  deleteconfirmclass(id) {
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.delete2(id);
      }
    });
  }
  deleteconfirm(id) {
    console.log(id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.delete(id);
      }
    });
  }

////////////////////////////////////////////////////////////////////////////////////

updatecontact() {


  // const token = localStorage.getItem('token');
  const headers = new Headers();

  const postData = this.formdata;
  // const httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: token
  //   })
  // };


  this.global.http.post(this.global.server + 'banners/updatecontact.php', postData, {})
    .subscribe(data => {
      console.log(data);
      // tslint:disable-next-line: triple-equals
      if (data['status'] == 'success') {
        this.modal.hide();
        // tslint:disable-next-line
       // this.ngOnInit();
        this.lenders('no');
        this.global.addToast({
          title: 'Updated successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })
       

      } else {
        

        //   alert();
        // tslint:disable-next-line
        this.buttonloading = false;
        this.global.addToast({
          title: 'failed',
          msg: data['statusmessage'],
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'error'
        })
      }
    }, error => {
      console.log(error);
      //this.loading = false;

    });
}
////////////////////////////////////////////////////////////////////////////////////












  chnagestatus(id, status) {

    this.statusloading[id] = 1;

    this.formdata.id = id;
    this.formdata.status = status;
    this.formdata.type = 'banner';
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server + 'banners/update.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
         // this.ngOnInit();
          this.lenders('no');
          this.global.addToast({
            title: 'Updated successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.statusloading[id] = 0;

        } else {
          this.statusloading[id] = 0;

          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }



}
