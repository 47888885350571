import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import 'sweetalert2/src/sweetalert2.scss';
//import 'datatables.net-fixedcolumns'


@Component({
  selector: 'app-teacher-attendance',
  templateUrl: './teacher-attendance.component.html',
  styleUrls: ['./teacher-attendance.component.scss']
})
export class TeacherAttendanceComponent implements OnInit {
  
  formInput: any={};
  public isSubmit: boolean;
  
  loading = false;
  buttonloading = false;
  statusloading = [];
  tabledata: any = {};
  count:any={};
  head:any={};
  dates:any={};
  teacherdata: any = {};
  years: any = {};
  months: any = {};
  months_yr: any = {};
  year: any = {};
  month: any = {};
  techername: any = {};
  dtOptions: DataTables.Settings = {};
  constructor(public global: GlobalService, public router: Router) {
    this.isSubmit = false;
  }
  ngOnInit() {
    this.lenders();
   /* this.dtOptions = {
        scrollY:        true,
        scrollX:        true,
        scrollCollapse: true,
        paging:         true,
        fixedColumns:   {
            leftColumns: 3,
            rightColumns: 1
        }
    } */


  }


  
  lenders() {

    this.loading = true;
    const token = localStorage.getItem('token');
    const postData = 'hi';
    const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token
          })
          
        };
   this.global.http.get(this.global.server2 + 'students/teacher_list.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.teacherdata = data['data'];
         

        } 
      }, error => {
        console.log(error);
        //this.loading = false;

      });   

	var current_year = new Date().getFullYear();
	var years_list = [];
    for(var i = 2021; i <= current_year; i++){
        years_list.push({value: i + ''});
    }
	this.years=years_list;
	this.year = new Date().getFullYear();
	

	this.months_yr = [
    { value: 1, text: 'January' ,selecteted:""},
    { value: 2, text: 'February',selecteted:"" },
    { value: 3, text: 'March' ,selecteted:""},
    { value: 4, text: 'April' ,selecteted:""},
    { value: 5, text: 'May' ,selecteted:""},
    { value: 6, text: 'June',selecteted:"" },
    { value: 7, text: 'July' ,selecteted:""},
    { value: 8, text: 'August',selecteted:"" },
    { value: 9, text: 'September',selecteted:"" },
    { value: 10, text: 'October' ,selecteted:""},
    { value: 11, text: 'November' ,selecteted:""},
    { value: 12, text: 'December' ,selecteted:""}
];
	const mon_arr=[];
	for(let i=0;i<new Date().getMonth()+1;i++){
		mon_arr.push(this.months_yr[i]);
		}	
	this.months=mon_arr;
	this.month= new Date().getMonth()+1;
	console.log(this.months);
	this.months[this.month-1].selecteted="selecteted";
	console.log(this.months);
	console.log(this.month);
    
	this.techername=0;
	this.global.http.post(this.global.server2 + 'attendance/teachers.php?month='+this.month+'&year='+this.year, postData, httpOptions)
      .subscribe(data => {
        if (data['status'] == "success") {
			console.log(data);
          this.tabledata = data['data'];
          this.head = data['table_header'];
          this.dates = data['dates'];
			console.log(this.tabledata);
          this.loading = false;
        } else {
          this.loading = false;
        }
      }, error => {
        console.log(error);
      });
  }


	getData(){
		this.months=this.months_yr;
	const mon_arr=[];
	for(let i=0;i<new Date().getMonth()+1;i++){
		mon_arr.push(this.months_yr[i]);
		}	
	console.log(mon_arr);
	if(this.year == new Date().getFullYear())
	this.months=mon_arr;
    const token = localStorage.getItem('token');
    const postData = 'hi';
    const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token
          })
          
        };
		console.log(this.techername);
			this.global.http.post(this.global.server2 + 'attendance/teachers.php?month='+this.month+'&year='+this.year+'&teacher='+this.techername, postData, httpOptions)
      .subscribe(data => {
        if (data['status'] == "success") {
			console.log(data);
          this.tabledata = data['data'];
          this.head = data['table_header'];
          this.dates = data['dates'];
			console.log(this.tabledata);
			console.log(this.head);
          this.loading = false;
        } else {
          this.loading = false;
        }
      }, error => {
        console.log(error);
      });
		}
	

}
