import {
	Component,
	OnInit
} from '@angular/core';
/* import {Component, Input, OnInit} from '@angular/core'; */
import {
	GlobalService
} from '../../services/global.service';
import {
	Router
} from '@angular/router';
import {
	HttpHeaders
} from '@angular/common/http';
import {
	ViewChild,	
	ElementRef
} from '@angular/core';
import {
	NgbCalendar,
	NgbDateParserFormatter,
	NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
	trigger
} from '@angular/animations';
import Swal from 'sweetalert2';
import {
	ActivatedRoute,
	ParamMap
} from '@angular/router';

import 'sweetalert2/src/sweetalert2.scss';
import { combineAll } from 'rxjs/operators';



@Component({
	selector: 'app-classdetails',
	templateUrl: './classdetails.component.html',
	styleUrls: ['./classdetails.component.scss']
})
export class ClassdetailsComponent implements OnInit {
	@ViewChild('modalProject', {
		static: false
	  }) modal: any;

	  	@ViewChild('modalProject1', {
		static: false
	  }) modal1: any;
	  
	collapse: any = {};
	formInput: any = {};
	public model: any;
	modelCustomDay: any;
	disabled = true;
	searchstring: any;
	all = false;
	public isSubmit: boolean;
	formdata: any = {};
	loading = false;
	buttonloading = false;
	addloading = false;
	tabledata: any = {};
	studentdata: any = {};
	assigned :any =[];
	class: any={};
	formInput4: any={};
	assignment: any=[];
	check:any;
	activeTab: any;
	data: any = {};
	count: any = {};
	cid: any;
	days: any=[];
	feed:any;
	test :any =[];
	type:any='add';
	feedhide:any;
	public uploadedFiles: Array < File > = [];
	constructor(private route: ActivatedRoute, public parserFormatter: NgbDateParserFormatter, public calendar: NgbCalendar, public global: GlobalService, public router: Router) {
		this.cid = this.route.snapshot.paramMap.get('id');
		console.log("ASDASDASdasdadad");
		console.log("ASDASDASdasdadad" + this.cid);
		this.isSubmit = false;
		console.log(this.global.userdata.role);
	}

	updateclass(){
		Swal.fire({
		  title: 'Are you sure?',
		  text: 'Do you want to Reschedule the Class?',
		  type: 'success',
		  showCloseButton: true,
		  showCancelButton: true
		}).then((willDelete) => {
		  if (willDelete.dismiss) {
			// Swal.fire('', 'Your imaginary file is safe!', 'error');
		  } else {
			this.updateclass1(); 
		  }  
		});
	  }
	  updateclass1(){
		 this.loading=true;
		 console.log("Update Class");
		 this.formInput.cid=this.cid;
		 console.log(this.formInput);
		 const token = localStorage.getItem('token');
		 const postData = this.formInput;
		 const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  Authorization: token
			})
		  }
		  this.global.http.post(this.global.server2 + 'classes/updateclass.php',postData , httpOptions)
		  .subscribe(data => {
			console.log(data);
			if (data['status'] == 'success') {
			  this.ngOnInit();
			  this.global.addToast({
				title: 'Class Scheduled successfully',
				msg: ' ',
				timeout: 8000,
				theme: 'default',
				position: 'top-right',
				type: 'success'
			  })
			}
			else{
				this.loading = false;
				this.global.addToast({
				  title: 'failed',
				  msg: data['status'],
				  timeout: 8000,
				  theme: 'default',
				  position: 'top-right',
				  type: 'error'
				})
			}
		  },error => {
			console.log(error);
		  });
	  
	  }
studentdetails() {
		console.log(this.formdata);
		const token = localStorage.getItem('token');
		const postData = {
			cid: this.cid
		};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token
			})
		};
		this.global.http.post(this.global.server2 + 'classes/classdetails.php', postData, httpOptions)
			.subscribe(data => {
				console.log("this is student list");
				console.log(data);
				if (data['status'] == true) {
					console.log("dataenter")
					this.studentdata = data['user'];
					this.class = data['class'];
					for (let i = 0; i < data['user'].length; i++) {
						  this.test.push({'label': data['user'][i].name, 'value':data['user'][i].id});
						  console.log("forloop");
						  console.log(this.test);
						//   if(!this.selectedsite || this.selectedsite== null){
						//     this.selectedsite = data['site'][0].id;
						//   }
						}
					// this.assignment = data['assignment'];
					// var d = this.assignment[0]['duedate'];
					// var a= d.split(" ");
					// this.assignment[0]['duedate']=a[0];
					// console.log(this.assignment);

					console.log("fdssfsf");
					console.log(this.studentdata);
					console.log(this.class);
					this.loading = false;
				} else {
					this.loading = false;
				}
			}, error => {
				console.log(error);
			});
	}
	addclass()
	{
		const token = localStorage.getItem('token');
			const postData = {
				cid: this.cid,
				sid: this.formInput.selecteddays,
				tid:this.class.teacher
			};
			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					Authorization: token
				})
			};
		this.global.http.post(this.global.server2 + '/classes/addstudent.php', postData, httpOptions)
				.subscribe(data => {
					if (data['status'] == 'success') {
			  console.log(data);
			  this.studentdetails();
			  this.modal.hide();
			  this.formInput.selecteddays=[];
					} else {
			  console.log(data);
					}
				}, error => {
					console.log(error);
				});
	  }
	  
updateclassstatus(){
    const token = localStorage.getItem('token');
		const postData = {
			cid: this.cid
		};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token
			})
		};
    this.global.http.post(this.global.server2 + '/classes/updatestatus.php', postData, httpOptions)
			.subscribe(data => {
				if (data['status'] == 'success') {
          console.log(data);
          
				} else {
          console.log(data);
				}
			}, error => {
				console.log(error);
			});
  }
  
  getdays(){
   

    
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();
 
   
    const postData = {};
   
    this.global.http.post(this.global.server2 + 'students/getstudents.php', postData, {})
      .subscribe(data => {
		  
        console.log(data['data']);
        // tslint:disable-next-line: triple-equals
        if (1) {
          this.days=[];
       console.clear();
          for (let i = 0; i < data['data'].length; i++) { 
			  
            console.log(data['data'][i]);
			if(data['data'][i].name!=null){
            this.days.push({'label': data['data'][i].name, 'value':data['data'][i].id});
			}
			
  
          }
		  console.log(this.days);
         
          //this.tname=this.tabledata['name'];
         
 
        } else {
          //   alert();
         
 
        }
      }, error => {
        console.log(error);
        //this.loading = false;
 
      });
  }
  deleteconfirm(id) {
    console.log("hello delete");
    console.log(id);
    console.log('id is '+id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.delete(id);
      }
    });
  }
  delete(id) {
    
    /* this.buttonloading = true;
  
    console.log(this.formdata); */
    // const token = localStorage.getItem('token');
    // const headers = new Headers();
  this.formdata.id = id;
  this.formdata.cid = this.cid;
    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'classes/deletestudents.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          //this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Deleted successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }
addassignment(){
	this.loading = true;
	console.log(JSON.stringify(this.formInput));
    this.formInput.cid=this.cid;
	console.log(this.uploadedFiles[0]);
	let formData: FormData = new FormData();
	if(this.uploadedFiles[0])
	{
	 const file: File = this.uploadedFiles[0];
	 console.log(file);
	 formData.append('file', file, file.name);
	}
	this.formInput.user=this.assigned;
	formData.append('data', JSON.stringify(this.formInput));
	console.log(formData);
	const token = localStorage.getItem('token');
	const postData=formData;
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'assignments/addassignments.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
		this.modal.hide();
        this.global.addToast({
          title: 'Assignment added successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        });
		this.loading = false;
		this.ngOnInit();
        this.buttonloading = false;
      }
      else{
		this.loading = false;
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['status'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });
  }
updateassignment(aid){
	console.log(aid);
	console.log("kjhjkjolkjlkj");
	console.log(this.formInput);
	const token = localStorage.getItem('token');
    const postData = this.formInput;
	postData['aid']=aid;
    console.log(postData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'assignments/updateassignments.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
		console.log(this.modal);
		this.ngOnInit();
		this.modal.hide();
        this.global.addToast({
          title: 'Assignment added successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })

        this.buttonloading = false;

      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['status'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });

}

postfeedback(aid){
	console.log(aid);
	console.log("kjhjkjolkjlkj");
	console.log(this.formInput);
	this.feed=this.formInput;
	console.log(this.feed);
	const token = localStorage.getItem('token');
    const postData = this.formInput;
	postData['aid']=aid;
    console.log(postData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'assignments/assignmentsfeedback.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
		this.feedhide=1;
        this.global.addToast({
          title: 'Feedback added successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })

        this.buttonloading = false;

      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['status'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });

}
ngOnInit() {
	this.loading = true;
	this.studentdetails();
this.check =0;
this.feedhide=0;
this.getdays();
}
}