import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
export class FormInput {
  username: any;
  password: any;
}

@Component({
  selector: 'app-teacherlogin',
  templateUrl: './teacherlogin.component.html',
  styleUrls: ['./teacherlogin.component.scss']
})
export class TeacherloginComponent implements OnInit {

  public isSubmit: boolean;
  formInput: FormInput;
  formdata: any = {};
  emailerror: any = 'no';
  loading = false;
  invalid = false;
  constructor(public global: GlobalService, public router: Router) { }

  ngOnInit() {
    localStorage.removeItem("token");
    this.global.storage.remove('userdata');
    this.global.storage.remove('userid');
    this.formInput = {
      username:  '',
      password:  '',
    };
    // this.global.storage.get('userdata').then((data) => {
    //   console.log('this is user data');
    //   console.log(data);
      
    //     });
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
  }

  login(form: any) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.formInput.username))
  {
    this.emailerror = 'no';
    
  }
  else
  {
    this.emailerror = 'yes';
    return;
  }
 
    this.invalid = false;
    this.loading = true;

    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
    this.formdata = this.formInput;
    console.log(this.formdata);

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json' ); 
    const postData =  this.formdata;
    this.global.http.post(this.global.server2+'teachers/teacherlogin.php', postData, { observe: 'response'} )
    .subscribe(data => {
      console.log(data.body);
      // tslint:disable-next-line: triple-equals
      if (data.body['status']== true){
        data.body['records'][0].role='agent';

        

        this.global.storage.set('userdata', data.body['records'][0]);
        this.global.userdata = data.body['records'][0];
        this.global.storage.set('userid', data.body['records'][0].id);
        localStorage.setItem('token', data.body['token']);
        localStorage.setItem('loginUserId', data.body['records'][0].id);
        // this.global.socket(data.body['records'][0].id); 
        console.log(this.global.userdata.role);
        this.loading = false;
        this.router.navigate(['/tdashboard']);

      } else{
     //   alert();
     this.invalid = true;
     this.loading = false;

      }
     }, error => {
      console.log(error);
      //this.loading = false;

    });
   }
}