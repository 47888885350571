import { Component, OnInit } from '@angular/core';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {
  Router,
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core'
@Component({
  selector: 'app-tprofile',
  templateUrl: './tprofile.component.html',
  styleUrls: ['./tprofile.component.scss']
})
export class TprofileComponent implements OnInit {
  @ViewChild('modalProject', {static: false}) modal: any;
  id:any;
  formInput:any={};
  addloading:any;
  loading :any;
  tabledata: any = {};
  team: any = {};
  formdata: any = {};
  profile: any = {};
  count: any = {};
  rating : number;
  reviewdata: any ={};
  public editProfile: boolean;
  public editProfileIcon: string;
  public editContact: boolean;
  public editContactIcon: string;
  public editOtherInfo: boolean;
  public editOtherInfoIcon: string;
  collapse :any ={};
  instrument:any={};
  buttonloading = false;
  public model: any;
  modelCustomDay: any;
  tableloading: any;
  navigation = 'select';
  showWeekNumbers = false;
  selectedtime: string;
  optionsloaded: boolean;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  siteoptions : any = {};
  data : any = {};
  video:any;
  teacherdata:any={};
  constructor(public parserFormatter: NgbDateParserFormatter,public global: GlobalService,private route: ActivatedRoute,private router: Router,private lightbox: Lightbox, private lightboxEvent: LightboxEvent, private lighboxConfig: LightboxConfig) {
    this.editProfile = false;
    this.editProfileIcon = 'icon-edit';
    this.editContact = false;
    this.editContactIcon = 'icon-edit';
    this.editOtherInfo = false;
    this.editOtherInfoIcon = 'icon-edit';
  }
  ngOnInit() {
    this.loading = true;
    this.tableloading = true;
    this.teacherdetails();
    
    this.studentreview('yes');
    this.loading='false'
  }
  updateteacher(){
    console.log("kjhjkjolkjlkj");
    console.log(this.teacherdata);
    const token = localStorage.getItem('token');
    const postData = this.teacherdata;
    console.log(postData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'teachers/updateteacherpersonal.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
		console.log(this.modal);
		this.ngOnInit();
		this.modal.hide();
        this.global.addToast({
          title: 'Profile Update successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })

        this.buttonloading = false;

      }
      else{ 
        this.buttonloading = false;
          this.global.addToast({
            title: 'Profile Not Update',
            msg: data['status'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });

}

  teacherdetails() {
    
		console.log(this.formdata);
		const token = localStorage.getItem('token');
		const postData = 'hi';
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token
			})

		};
		this.global.http.post(this.global.server2 + 'teachers/teacherdetails.php', postData, httpOptions)
			.subscribe(data => {
				console.log("this is student list");
				console.log(data);
				if (data['status'] == true) {
					console.log("dataenter");
          console.log(data);
          this.teacherdata=data['data'];
          this.instrument=data['data'].instruments;
          console.log(this.teacherdata);
          this.video=this.teacherdata['video']
          console.log(this.video)
					this.loading = false;
				} else {
					this.loading = false;
				}
			}, error => {
				console.log(error);
			});
	}
  studentreview(loading) {
    if(loading=='yes'){
      this.loading = true;
     }
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const postData = 'hi';
    const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token
			})

		};
    this.global.http.post(this.global.server2 + 'teachers/studentreview.php', postData, httpOptions)
        .subscribe(data => {
            console.log(data);
            if (data['status'] == true) {
                this.rating = data['rating'];
                this.reviewdata = data['data'];
                console.log(this.reviewdata);
                this.tableloading = false;
            } else {
                this.loading = false;
            }
        }, error => {
            console.log(error);
        });
}
}
