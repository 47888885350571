/* 
import { Component } from '@angular/core';
import { EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';

@Component({
selector: 'calendar',
providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
// specifies the template string for the Schedule component
template: `<ejs-schedule width='100%' height='550px' 
  [eventSettings]="eventSettings" > </ejs-schedule>`
})
export class DemocalendarComponent {
public eventSettings: EventSettingsModel = {
 
    fields: {
        id: 'Id',
        subject: { name: 'Subject' },
        isAllDay: { name: 'IsAllDay' },
        startTime: { name: 'StartTime' },
        endTime: { name: 'EndTime' }
    }
};
} */
import { Component } from '@angular/core';


@Component({
  selector: 'calendar',

  // specifies the template string for the Schedule component
  // templateUrl: './democalendar.component.html'
  template: `<h1>Calendar Working</h1>`
})
export class DemocalendarComponent { } 
/* import { Component, ViewEncapsulation } from "@angular/core";
import { GroupModel } from "@syncfusion/ej2-angular-schedule";
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';
@Component({
  selector: "app-root",
 // template: `<ejs-schedule> </ejs-schedule>`,
  templateUrl: "./democalendar.component.html",
 styleUrls: ["./democalendar.component.scss"],
 providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
  encapsulation: ViewEncapsulation.None
})

export class DemocalendarComponent {
  public group: GroupModel = {
    resources: ["Doctors"]
  };
  public allowMultipleDoctors: Boolean = true;
  public doctorDataSource: Object[] = [
    { text: "Will Smith", id: 1, color: "#ea7a57", designation: "Cardioligst" },
    { text: "Alice", id: 2, color: "#7fa900", designation: "Neurologist" }
  ];
}
/* import { Component, ViewEncapsulation,ViewChild } from '@angular/core';
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { PopupOpenEventArgs, ScheduleComponent, CurrentAction } from "@syncfusion/ej2-angular-schedule";
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService } from '@syncfusion/ej2-angular-schedule';
@Component({
  selector: "calendar",
  templateUrl: './democalendar.component.html',
  styleUrls: ["./democalendar.component.scss"],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
  encapsulation: ViewEncapsulation.None
})

export class DemocalendarComponent {
  events: any;
  eventData: any;
  @ViewChild("scheduleObj",{static: false})
  public scheduleObj: ScheduleComponent;
  private selectionTarget: Element;
  public onPopupOpen(args: PopupOpenEventArgs): void {
    this.selectionTarget = null;
    this.selectionTarget = args.target;
  }
  public onDetailsClick(): void {
    this.onCloseClick();
    const data: Object = this.scheduleObj.getCellDetails(
      this.scheduleObj.getSelectedElements()
    ) as Object;
    this.scheduleObj.openEditor(data, "Add");
  }
  public onAddClick(): void {
    this.onCloseClick();
    const data: Object = this.scheduleObj.getCellDetails(
      this.scheduleObj.getSelectedElements()
    ) as Object;
    console.log(data);                                
    const eventData: {
      [key: string]: Object;
    } = this.scheduleObj.eventWindow.getObjectFromFormData(
      "e-quick-popup-wrapper"
    );
    this.scheduleObj.eventWindow.convertToEventData(
      data as { [key: string]: Object },
      eventData
    );
    eventData.Id = (this.scheduleObj.eventBase.getEventMaxID() as number) + 1;
    this.scheduleObj.addEvent(eventData);
  }
  public onEditClick(args: any): void {
    if (this.selectionTarget) {
 
      let eventData: {
        [key: string]: Object;
      } = this.scheduleObj.getEventDetails(this.selectionTarget) as {
        [key: string]: Object;
      };
      let currentAction: CurrentAction = "Save";
      if (
        !isNullOrUndefined(eventData.RecurrenceRule) &&
        eventData.RecurrenceRule !== ""
      ) {
        if (args.target.classList.contains("e-edit-series")) {
          currentAction = "EditSeries";
          eventData = this.scheduleObj.eventBase.getOccurrenceEvent(eventData);
          console.log(eventData);
        } else {
          currentAction = "EditOccurrence";
        }
      }
      this.scheduleObj.openEditor(eventData, currentAction);
    }
  }
  public onDeleteClick(args: any): void {
    this.onCloseClick();
    if (this.selectionTarget) {
      const eventData: {
        [key: string]: Object;
      } = this.scheduleObj.getEventDetails(this.selectionTarget) as {
        [key: string]: Object;
      };
      let currentAction: CurrentAction;
      if (
        !isNullOrUndefined(eventData.RecurrenceRule) &&
        eventData.RecurrenceRule !== ""
      ) {
        currentAction = args.target.classList.contains("e-delete-series")
          ? "DeleteSeries"
          : "DeleteOccurrence";
      }
      this.scheduleObj.deleteEvent(eventData, currentAction);
    }
  }
  public onCloseClick(): void {
    this.scheduleObj.quickPopup.quickPopupHide();
  }
} */