import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { NgbdRatingDecimal } from './rating-decimal';

export class FormInput {
  id: any;
  userid: any;
  desc: any;
  star: any;
  tid: any;
}

export class FormInput1 {
  id:any;
 name:any;
 about:any;
 status:any;
  video : any;
}

@Component({
  selector: 'app-studentpayment',
  templateUrl: './studentpayment.component.html',
  styleUrls: ['./studentpayment.component.scss']
})
export class StudentpaymentComponent implements OnInit {

  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));

  @ViewChild('modalProject', {
    static: false
  }) modal: any;
  @ViewChild('modalProject1', {
    static: false
  }) modal1: any;

  // dtExportButtonOptions: any = {};
  dtExportButtonOptions = {
    dom: 'Bfrtip',
  };
  formInput: any={};
  formInput2: FormInput;
  form: any;
  rating : number;
  type: any = 'add';
  public isSubmit: boolean;
  formdata: any = {};
  loading = false;
  buttonloading = false;
  statusloading = [];
  activeTab : any;
  albums:any;
  id:any;
  getSantizeUrl:any;
  tname:any;
  tabledata: any = {};
  reviewdata: any ={};
  teacherclass: any ={};
  editdata: any = {};
  countdata: any = {};
  editOtherInfo:any;
  editOtherInfoIcon:any;
  formInput3: FormInput1;
  totalpayment : any;
  pendingpayment: any;
  totalwallet : any;
  totalcount : any;
  totalamount : any;
  payoutdata : any;
  teachertable : any;
  editContact:any;
  editContactIcon:any;
  editProfile:any;
  editProfileIcon:any;
  public options: any = [{
      value: '0',
      label: 'Alabama'
    },
    {
      value: '1',
      label: 'Wyoming'
    },
    {
      value: '2',
      label: 'Coming'
    },
    {
      value: '3',
      label: 'Henry Die'
    },
    {
      value: '4',
      label: 'John Doe'
    }
  ];
  selectedOption = '3';
  public addform = new FormGroup({
    files: this.filesControl
  });
  public uploadedFiles: Array < File > = [];
  public uploadedFiles2: Array < File > = [];
  selectedsite : any; 
  siteoptions: any[];
  constructor(private route: ActivatedRoute,public global: GlobalService, public router: Router) {
    this.id = this.route.snapshot.paramMap.get('id');
console.log(this.id);
    this.isSubmit = false;

  }
  ngOnInit() {
    console.log('hello mathan');
    this.walletdisplay();
    this.activeTab = 'contact';

  }
  walletdisplay()
  {
    this.loading = true;
    
    this.global.http.get(this.global.server2 + 'payment/studentpayment.php')
    .subscribe(data => {
      console.log(data);
      // tslint:disable-next-line: triple-equals
      if (data['success'] == true) {

       console.log('success');
       console.log(data);
       this.teachertable= data['teachertrans'];
       console.log(data['totalpayment'],data['pendignwallet'],data['totalwallet']);
       this.totalpayment = data['totalpayment'];
       console.log(data['pendingwallet']);
       this.pendingpayment = data['pendingwallet'];
       console.log(this.pendingpayment);
       this.totalwallet = data['totalwallet'];
       this.totalcount = data['totalcount'];
       this.totalamount = data['TotalAmount'];
    this.loading = false;

      } else {
        //   alert();
        this.loading = false;

      }
    }, error => {
      console.log(error);
      //this.loading = false;

    }); 
  }

}
