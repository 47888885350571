import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import {NgbAccordionModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import {TinymceModule} from 'angular2-tinymce';
import {DataTablesModule} from 'angular-datatables';
import {FormsModule} from '@angular/forms';
import {TagInputModule} from 'ngx-chips';
/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LeadsComponent } from './pages/leads/leads.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { LeadComponent } from './pages/lead/lead.component';
import {CustomFormsModule} from 'ng2-validation';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {SelectModule} from 'ng-select';
import { BannersComponent } from './pages/banners/banners.component';
import { LendersComponent } from './pages/lenders/lenders.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { IonicModule } from '@ionic/angular';
import { JwtModule } from '@auth0/angular-jwt';
// import { GlobalService } from './services/global.service';
import {ToastyModule} from 'ng2-toasty';
import { ViewagentComponent } from './pages/viewagent/viewagent.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
//import {AmazingTimePickerModule} from 'amazing-time-picker';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProductsComponent } from './pages/products/products.component';
import { AddproductComponent } from './pages/addproduct/addproduct.component';
import { ViewproductComponent } from './pages/viewproduct/viewproduct.component';
import { CategoryComponent } from './pages/category/category.component';
import { AdditemsComponent } from './pages/additems/additems.component';
import { UpdateproductComponent } from './pages/updateproduct/updateproduct.component';
import { NillComponent } from './pages/nill/nill.component';
import { AddsubitemComponent } from './pages/addsubitem/addsubitem.component';
import { UpdatesubitemComponent } from './pages/updatesubitem/updatesubitem.component';
import { RouterModule, Routes } from '@angular/router';
import { AddcategoryComponent } from './pages/addcategory/addcategory.component';
import { EditcategoryComponent } from './pages/editcategory/editcategory.component';
import { UsersComponent } from './pages/users/users.component';
import { AdduserComponent } from './pages/adduser/adduser.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ViewuserComponent } from './pages/viewuser/viewuser.component';
import { VieworderComponent } from './pages/vieworder/vieworder.component';
import { EdititemsComponent } from './pages/edititems/edititems.component';
import { OffersComponent } from './pages/offers/offers.component';
import { EditofferComponent } from './pages/editoffer/editoffer.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { TeacherloginComponent } from './pages/teacherlogin/teacherlogin.component';
import { TeacherlistclassesComponent } from './pages/teacherlistclasses/teacherlistclasses.component';
import { ClassdetailsComponent } from './pages/classdetails/classdetails.component';
import { StudentprofileComponent } from './pages/studentprofile/studentprofile.component';
import { StudentComponent } from './pages/student/student.component';
import { TeacherComponent } from './pages/teacher/teacher.component';
import { TeacherprofileComponent } from './pages/teacherprofile/teacherprofile.component';
import { InstrumentsComponent } from './pages/instruments/instruments.component';
import { EditinstrumentsComponent } from './pages/editinstruments/editinstruments.component';

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { StudentlistComponent } from './pages/studentlist/studentlist.component';
import { StudentdetailsComponent } from './pages/studentdetails/studentdetails.component';
import { TprofileComponent } from './pages/tprofile/tprofile.component';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

import { ClassesComponent } from './pages/classes/classes.component';
/* import { ScheduleModule } from '@syncfusion/ej2-angular-schedule'; */
import { DemocalendarComponent } from './pages/democalendar/democalendar.component';
import { DemofullcalendarComponent } from './pages/demofullcalendar/demofullcalendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PayoutsComponent } from './pages/payouts/payouts.component';
import { TeacherpaymentComponent } from './pages/teacherpayment/teacherpayment.component';
import { StudentpaymentComponent } from './pages/studentpayment/studentpayment.component';
import { DesktoploginComponent } from './pages/desktoplogin/desktoplogin.component';
import { TeacherdashboardComponent } from './pages/teacherdashboard/teacherdashboard.component';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';
import { StudentloginComponent } from './pages/studentlogin/studentlogin.component';
import { TeacherViewComponent } from './pages/teacher-view/teacher-view.component';
import { StudentPaymentComponent } from './pages/student-payment/student-payment.component';
import { TeacherAttendanceComponent } from './pages/teacher-attendance/teacher-attendance.component';

/* import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime'; */
/* import { FlatpickrModule } from 'angularx-flatpickr'; */
export function jwtTokenGetter() {
  return localStorage.getItem('token');
}
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    SafePipe,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LeadsComponent,
    AgentsComponent,
    LeadComponent,
    BannersComponent,
    LendersComponent,
    DashboardComponent,
    LoginComponent,
    ViewagentComponent,
    SettingsComponent,
    ProductsComponent,
    AddproductComponent,
    ViewproductComponent,
    CategoryComponent,
    AdditemsComponent,
    UpdateproductComponent,
    NillComponent,
    AddsubitemComponent,
    UpdatesubitemComponent,
    AddcategoryComponent,
    EditcategoryComponent,
    UsersComponent,
    AdduserComponent,
    OrdersComponent,
    ViewuserComponent,
    VieworderComponent,
    EdititemsComponent,
    OffersComponent,
    EditofferComponent,
    NotificationsComponent,
    TeacherloginComponent,
    TeacherlistclassesComponent,
    ClassdetailsComponent,
    StudentprofileComponent,
    StudentComponent,
    TeacherComponent,
    TeacherprofileComponent,
    InstrumentsComponent,
    EditinstrumentsComponent,
    StudentlistComponent,
    StudentdetailsComponent,
    TprofileComponent,
    ClassesComponent,
    DemocalendarComponent,
    DemofullcalendarComponent,
    PayoutsComponent,
    TeacherpaymentComponent,
    StudentpaymentComponent,
    DesktoploginComponent,
    TeacherdashboardComponent, 
    AdmindashboardComponent,
    StudentloginComponent,
    TeacherViewComponent,
    StudentPaymentComponent,
    TeacherAttendanceComponent,

  ],
  imports: [
    /* FlatpickrModule.forRoot(), */
 
    NgbModalModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter

      }
    }),
    // RouterModule.forRoot([{ path: "welcome", component: AddprojectComponent }], { useHash: true }),
    RouterModule.forRoot([{ path: 'products' , component: ProductsComponent}], { useHash: true }),
    IonicModule.forRoot({mode: 'ios'}),
    IonicStorageModule.forRoot(),
    ToastyModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FormsModule,
    DataTablesModule,
    TagInputModule,
    TinymceModule,
    CustomFormsModule,
    NgbProgressbarModule ,
    SelectModule,
    FileUploadModule,
    NgbAccordionModule,
    NgbCollapseModule,
/*     ScheduleModule, */
    //AmazingTimePickerModule,
    NgbDatepickerModule,
    CommonModule,
    NgxSkeletonLoaderModule,

     ],
  providers: [NavigationItem, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
