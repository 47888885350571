
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-student-payment',
  templateUrl: './student-payment.component.html',
  styleUrls: ['./student-payment.component.scss']
})
export class StudentPaymentComponent implements OnInit {
  public isSubmit: boolean;
  loading = false;
  tabledata: any = {};

  countdata: any = {};

  constructor(public global: GlobalService, public router: Router) {
    this.isSubmit = false;

  }

 

  ngOnInit() {
    this.lenders('yes');
  }


  lenders(loading) {

    if(loading=='yes'){
      this.loading = true;
     }
    // this.formdata = this.formInput;
   // console.log(this.formdata);
    // const token = localStorage.getItem('token');
    // const headers = new Headers();

    //this.formdata.sid = this.selectedsite;
    //const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.get(this.global.server2 + 'students/getpayment.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.tabledata = data['data'];
          this.countdata = data['count'];
         
          let i;
          console.log(this.tabledata);
          // for (i = 0; i < data['site'].length; i++) {
          //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
          //     this.selectedsite = data['site'][0].id;

          //   }
          // }
          
          this.loading = false;

        } else {
          //   alert();
          this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }



}
