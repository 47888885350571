import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import 'sweetalert2/src/sweetalert2.scss';

@Component({
  selector: 'app-teacher-view',
  templateUrl: './teacher-view.component.html',
  styleUrls: ['./teacher-view.component.scss']
})
export class TeacherViewComponent implements OnInit {
  
  formInput: any={};
  public isSubmit: boolean;
  
  loading = false;
  buttonloading = false;
  statusloading = [];
  tabledata: any = {};
  count:any={};
  constructor(public global: GlobalService, public router: Router) {
    this.isSubmit = false;
  }
  ngOnInit() {
    this.lenders();
  }
  lenders() {

    this.loading = true;
    const token = localStorage.getItem('token');
    const postData = 'hi';
    const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token
          })
          
        };

    this.global.http.post(this.global.server2 + 'classes/teacherView.php', postData, httpOptions)
      .subscribe(data => {
        if (data['status'] == true) {
          this.tabledata = data['data'];
          this.count=data['count'];
          this.loading = false;
        } else {
          this.loading = false;
        }
      }, error => {
        console.log(error);
      });
  }

}
