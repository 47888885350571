import { Component, OnInit } from '@angular/core';
/* import {Component, Input, OnInit} from '@angular/core'; */
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { trigger } from '@angular/animations';
import Swal from 'sweetalert2';
import {
  ActivatedRoute,
  ParamMap
} from '@angular/router';


const now = new Date();


@Component({
  selector: 'app-studentdetails',
  templateUrl: './studentdetails.component.html',
  styleUrls: ['./studentdetails.component.scss']
})
export class StudentdetailsComponent implements OnInit {
  @ViewChild('modalProject', {
		static: false
	  }) modal: any;
  collapse : any = {};
  public model: any;
  modelCustomDay: any;
  filter: any;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  disabled = true;
  searchstring : any;
  all = false;
  formInput: any;
  countdata: any = {};
  reviewdata: any;
  albums:any;
  form: any;
  userdata:any={};
  assignmentdata:any={};
  classesdata:any={};
  public isSubmit: boolean;
  formdata: any = {};
  teacherdata : any;
  loading = false;
  addloading = false;
  tabledata: any = {};
  paymentdata : any = {};
  totalamount: any;
  editProfile:any;
  editProfileIcon:any;
  teacherclass:any;
  newselected: any = {};
  activeTab :any;
  siteoptions : any = {};
  data : any = {};
  count: any = {};
  sid:any;
  selectedassigne ;
  selectedlead: any = {} ;
  optionsloaded: boolean;
  selectedtime: string;
  constructor(private route: ActivatedRoute,public parserFormatter: NgbDateParserFormatter, public calendar: NgbCalendar, public global: GlobalService, public router: Router) {
    this.sid = this.route.snapshot.paramMap.get('id');
    console.log(this.sid);
        this.isSubmit = false;
    console.log(this.global.userdata.role);
  }



  studentdetails() {
    this.loading = true;
		console.log(this.formdata);
		const token = localStorage.getItem('token');
		const postData = {
			id: this.sid
		};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: token
			})

		};
		this.global.http.post(this.global.server2 + 'teachers/studentdetail.php', postData, httpOptions)
			.subscribe(data => {
				console.log("this is student list");
				console.log(data);
				if (data['status'] == true) {
					console.log("dataenter")
          this.userdata=data['data'];
          this.classesdata=data['classes'];
          this.assignmentdata=data['assignment']
          console.log(this.assignmentdata);
					this.loading = false;
				} else {
					this.loading = false;
				}
			}, error => {
				console.log(error);
			});
	}
  ngOnInit() {
    const table: any = $('#report-table1');
    const table2: any = $('#report-table2');


    this.activeTab = 'gallery';
    this.studentdetails();
  }
}
