import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';

export class FormInput {
  pid: any;
  lendername: any;
  image: any;
  link: any;
  lid: any;

}
export class FormInput1 {
  id:any;
 name:any;
 about:any;
 status:any;

}

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));

  @ViewChild('modalProject', {
    static: false
  }) modal: any;

  // dtExportButtonOptions: any = {};
  dtExportButtonOptions = {
    dom: 'Bfrtip',
  };
  getid: any;
  formInput: any={};
  formInput2: FormInput;
  formInput3: FormInput1;
  formInput4: any = {};

  form: any;
    type: any = 'add';
  public isSubmit: boolean;
  formdata: any = {};
  loading = false;
  buttonloading = false;
  statusloading = [];
  products : any ={};
  teacherData : any ={};
  option = [];
  tabledata: any = {};
  editdata: any = {};
  countdata: any = {};
  public options: any = [{
      value: '0',
      label: 'Alabama'
    },
    {
      value: '1',
      label: 'Wyoming'
    },
    {
      value: '2',
      label: 'Coming'
    },
    {
      value: '3',
      label: 'Henry Die'
    },
    {
      value: '4',
      label: 'John Doe'
    }
  ];
  selectedOption = '3';
  public addform = new FormGroup({
    files: this.filesControl
  });
  public uploadedFiles: Array < File > = [];
  public uploadedFiles2: Array < File > = [];
  selectedsite : any; 
    error : any; 

  siteoptions: any[];
  constructor(public global: GlobalService, public router: Router) {
    this.isSubmit = false;

  }
  addagent(aa) {
    console.log(this.filesControl.value);
    console.log(this.formInput);
    console.log(this.uploadedFiles);

  }
  ngOnInit() {
    //this.filesControl.enable();
    //     this.filesControl.valueChanges.subscribe(() => {
    //       console.log(this.filesControl);
    //       if(this.filesControl.value.length == 0) {
    //          this.filesControl.enable();
    //         } else{
    //             this.filesControl.disable();
    //            }
    //     }

    // );

    this.formInput = {
      pid: '',
      lendername: '',
      image: '',
      link: '',
      lid: '',
    };
    this.teacherData = {
      
      
    };
    this.formInput3 = {
      id:'',
      name: '',
      status: '',
      about: '',

    };
    this.formInput4 = {
      id:'',
      name: '',
      status: '',
      about: '',

    };
    this.dtExportButtonOptions = {
      dom: 'Bfrtip',
    };
    this.lenders('yes');
    // this.product();
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
  }
  product(){
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();
  
    this.formdata.name = "test";
    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };
  
  
    this.global.http.post(this.global.server + 'offers/options.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
  
          console.log(data['products'][0].name);
          this.option = [];
  
          let i;
          for (i = 0; i < data['products'].length; i++) {
            this.option.push({'label': data['products'][i].name, 'value':data['products'][i].pid});
            // if(!this.option || this.option== null){
              // this.option = data['options'][0].sid;
  
            // }
            
          }
          this.modal.show();
          console.log(this.option);
          // this.countdata = data['data'].count;
          // this.siteoptions = [];
           
          this.formInput.category = '0';
          // this.formInput.color = '1';
  
          // for (i = 0; i < data['data'].list.length; i++) {
          //   this.siteoptions.push({'label': data['data'].list[i].name, 'value':data['data'].list[i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
              // this.selectedsite = data['list'][0].id;
  
          //   }
            
          // }
          
          this.loading = false;
  
        } else {
          //   alert();
          this.loading = false;
  
        }
      }, error => {
        console.log(error);
        //this.loading = false;
  
      });
  }

  openupdate(index) {
    // //this.editdata = this.tabledata[index];
    // this.formInput2 = {
    //   pid: this.tabledata[index].pid,
    //   lendername: this.tabledata[index].name,
    //   image: this.tabledata[index].img,
    //   link: this.tabledata[index].url,
    //   lid: this.tabledata[index].id,
    // };
    // console.log(this.editdata);
    // this.type = 'update';
    this.modal.show();
  }

  lenders(loading) {

    if(loading=='yes'){
      this.loading = true;
     }

    // this.formdata = this.formInput;
    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    this.formdata.sid = this.selectedsite;
    const postData = this.formdata;
    console.log(postData);
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'teachers/getteachers.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] =='success') {

          this.tabledata = data['teachers'];
          console.log(this.tabledata);
          this.countdata = data['count'];
          this.countdata=this.countdata[0];
          console.log(this.countdata);
          this.siteoptions = [];
          let i;
          // for (i = 0; i < data['site'].length; i++) {
          //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
          //     this.selectedsite = data['site'][0].id;

          //   }
          // }
          
          this.loading = false;

        } else {
          //   alert();
          this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }


  addoffer(form: any) {
    // console.log(form);
    console.log(this.formInput3);
    console.log(form.valid);
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(this.formInput4.email.match(mailformat))
    {
    }
    else
    {
    // alert("You have entered an invalid email address!");
    this.error = 'email';
    return ;
    }
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }
    if (!this.uploadedFiles[0]) {
      this.isSubmit = true;
alert('Select the image');
      return;
    }



    this.buttonloading = true;
    // this.formdata = this.formInput;
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    const headers = new Headers();
    this.formInput.sid = this.selectedsite ;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Accept: 'application/json',
    //     Authorization: token
    //   })
    // };
    let formData: FormData = new FormData();
    console.log(this.uploadedFiles);
    const file: File = this.uploadedFiles[0];
    formData.append('file', file, file.name);
    // console.log(this.formInput4.toString());
    formData.append('data', JSON.stringify(this.formInput4));
    this.global.http.post(this.global.server2 + 'teachers/addteacher.php', formData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles = [];
          this.global.addToast({
            title: 'Teacher added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }
  updateoffer(id)
  {
  this.router.navigate(['editoffer/'+id]);
  }


  updatelender(form: any) {
    console.log(this.formInput3);
    console.log(form.valid);
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }
    this.buttonloading = true;
    const headers = new Headers();
    let formData: FormData = new FormData();
    if (this.uploadedFiles[0]) 
    {
      const file: File = this.uploadedFiles[0];
      formData.append('file', file, file.name);
    } 
    formData.append('data', JSON.stringify(this.formInput3));
    console.log(formData);
   
    this.global.http.post(this.global.server2 + 'teachers/updateteacher.php', formData, {})
    .subscribe(data => {
        console.log("hello 2");
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles2 = [];
          this.global.addToast({
            title: 'Agent added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }




  delete(id) { 


    this.buttonloading = true;
    this.formInput3.id = id;

    console.log(this.formdata);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formInput3;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'teachers/delete.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Deleted successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }
  deleteconfirm(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.delete(id);
      }
    });
  }


  chnagestatus(id, status) {
    

    this.statusloading[id] = 1;

    this.formInput3.id = id;
    this.formInput3.status = status;

    console.log(this.formInput3);
    // const token = localStorage.getItem('token');
    const headers = new Headers();

    const postData = this.formInput3;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'teachers/updateteacher.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
         // this.ngOnInit();
          this.lenders('no');
          this.global.addToast({
            title: 'Updated successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.statusloading[id] = 0;

        } else {
          this.statusloading[id] = 0;

          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }




}
