import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { LeadsComponent } from './pages/leads/leads.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { LeadComponent } from './pages/lead/lead.component';
import { OffersComponent } from './pages/offers/offers.component';
import { BannersComponent } from './pages/banners/banners.component';
import { LendersComponent } from './pages/lenders/lenders.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ViewagentComponent } from './pages/viewagent/viewagent.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProductsComponent } from './pages/products/products.component';
import { AddproductComponent } from './pages/addproduct/addproduct.component';
import { ViewproductComponent } from './pages/viewproduct/viewproduct.component';
import { CategoryComponent } from './pages/category/category.component';
import { AddcategoryComponent } from './pages/addcategory/addcategory.component';
import { EditcategoryComponent } from './pages/editcategory/editcategory.component';
import { UsersComponent } from './pages/users/users.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { EdititemsComponent } from './pages/edititems/edititems.component';
import { StudentprofileComponent } from './pages/studentprofile/studentprofile.component';
import { EditofferComponent } from './pages/editoffer/editoffer.component';
import { ViewuserComponent } from './pages/viewuser/viewuser.component';
import { VieworderComponent } from './pages/vieworder/vieworder.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AdduserComponent } from './pages/adduser/adduser.component';
import { AdditemsComponent } from './pages/additems/additems.component';
import { AddsubitemComponent } from './pages/addsubitem/addsubitem.component';
import { UpdatesubitemComponent } from './pages/updatesubitem/updatesubitem.component';
import { UpdateproductComponent } from './pages/updateproduct/updateproduct.component';
import { NillComponent } from './pages/nill/nill.component';
import {  AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { TeacherloginComponent } from './pages/teacherlogin/teacherlogin.component';
import {TeacherlistclassesComponent} from './pages/teacherlistclasses/teacherlistclasses.component';
import {ClassdetailsComponent}from './pages/classdetails/classdetails.component' ;
import { StudentComponent } from './pages/student/student.component';
import { TeacherComponent } from './pages/teacher/teacher.component';
import { TeacherprofileComponent } from './pages/teacherprofile/teacherprofile.component';
import { InstrumentsComponent } from './pages/instruments/instruments.component';
import { EditinstrumentsComponent } from './pages/editinstruments/editinstruments.component';
import{StudentlistComponent} from './pages/studentlist/studentlist.component';
import{StudentdetailsComponent} from './pages/studentdetails/studentdetails.component';
import{TprofileComponent} from './pages/tprofile/tprofile.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { DemocalendarComponent } from './pages/democalendar/democalendar.component';
import { DemofullcalendarComponent } from './pages/demofullcalendar/demofullcalendar.component'
import { PayoutsComponent } from './pages/payouts/payouts.component';
import { TeacherpaymentComponent } from './pages/teacherpayment/teacherpayment.component';
import { StudentpaymentComponent } from './pages/studentpayment/studentpayment.component';
import { DesktoploginComponent } from './pages/desktoplogin/desktoplogin.component';
import { TeacherdashboardComponent } from './pages/teacherdashboard/teacherdashboard.component';
import { AdmindashboardComponent } from './pages/admindashboard/admindashboard.component';
import { StudentloginComponent } from './pages/studentlogin/studentlogin.component';
import { TeacherViewComponent } from './pages/teacher-view/teacher-view.component';
import { StudentPaymentComponent } from './pages/student-payment/student-payment.component';
import { TeacherAttendanceComponent } from './pages/teacher-attendance/teacher-attendance.component';


const routes: Routes = [
  
  {
    path: 'teacherlogin',
    component: LoginComponent,
  },
  {
    path: 'studentlogin',
    component: StudentloginComponent,
   

  }, 
  {
    path: 'desktoplogin',
    component: DesktoploginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,

  },
  
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        component : DemofullcalendarComponent,
        canActivate: [AuthGuard]

      },
      {
        path: '',
        component : TeacherdashboardComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'calendar',
        component: DemocalendarComponent,
        canActivate: [AuthGuard]
    
      },
      {
        path : 'teacherpayment',
        component: TeacherpaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path : 'studentpayment',
        component : StudentpaymentComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'payouts',
        component: PayoutsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'fullcalendar',
        component : DemofullcalendarComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]

      },{
        path: 'offers',
        component: OffersComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'teacher',
        component: TeacherComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'calendar',
      //   component: DemocalendarComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path : 'classes',
        component: ClassesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'viewagent/:id',
        component: ViewagentComponent,
        canActivate: [AuthGuard]
        },
        {
        path: 'vieworder/:id',
        component: VieworderComponent,
        canActivate: [AuthGuard]
        },
        {
        path: 'viewuser/:id',
        component: ViewuserComponent,
        canActivate: [AuthGuard]
        },
        {
        path: 'edititems/:id',
        component: EdititemsComponent,
        canActivate: [AuthGuard]
        },
        {
        path: 'editinstruments/:id',
        component: EditinstrumentsComponent,
        canActivate: [AuthGuard]
      },
        {
        path: 'editoffer/:id',
        component: EditofferComponent,
        canActivate: [AuthGuard]
        },
         {
        path: 'nill',
        component: NillComponent,
        canActivate: [AuthGuard]
        },
       {
        path: 'leads/:id',
        component: LeadsComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'studentprofile/:id',
        component: StudentprofileComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'classdetails/:id',
        component:ClassdetailsComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'studentdetails/:id',
        component: StudentdetailsComponent,
        canActivate: [AuthGuard]
      },
       {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard]

      },
        {
        path: 'agents',
        component: AgentsComponent,
        canActivate: [AuthGuard]

      }, 
       {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'student',
        component: StudentComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'adduser',
        component: AdduserComponent,
        canActivate: [AuthGuard]

      },
         {
        path: 'category',
        component: CategoryComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'tdashboard',
        component : TeacherdashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'teacherlistclass',
        component: TeacherlistclassesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'studentlist',
        component: StudentlistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'tprofile',
        component: TprofileComponent,
        canActivate: [AuthGuard]
      },
        {
        path: 'addcategory',
        component: AddcategoryComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'editcategory/:id',
        component: EditcategoryComponent,
        canActivate: [AuthGuard]

      },
        {
        path: 'additems',
        component: AdditemsComponent,
        canActivate: [AuthGuard]

      }, 
         {
        path: 'addsubitems/:id',
        component: AddsubitemComponent,
        canActivate: [AuthGuard]

      }, 
          {
        path: 'updatesubitems/:id',
        component: UpdatesubitemComponent,
        canActivate: [AuthGuard]

      }, 
      {
        path: 'instrumentlist',
        component: ProductsComponent,
        canActivate: [AuthGuard]

      },
      {
        path:'admindashboard',
        component : AdmindashboardComponent,
        canActivate : [AuthGuard]
      },
        {
          path: 'instruments',
          component: InstrumentsComponent,
          canActivate: [AuthGuard]
        },
        {
        path: 'addproducts',
        component: AddproductComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'lead/:id',
        component: LeadComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'product/:id',
        component: UpdateproductComponent,
        canActivate: [AuthGuard]

      },
       {
        path: 'banners/:id',
        component: BannersComponent
      },
      {
        path: 'teacherprofile/:id',
        component: TeacherprofileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'lenders',
        component: LendersComponent,
        canActivate: [AuthGuard]

      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard]

      }
     ,
     {
      path: 'teacherview',
      component: TeacherViewComponent,
      canActivate: [AuthGuard]

    }
   ,

   {
    path: 'student-payment',
    component: StudentPaymentComponent,
    canActivate: [AuthGuard]

  }
 ,

 {
  path: 'teacher-attendance',
  component: TeacherAttendanceComponent,
  canActivate: [AuthGuard]

}
,

    {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: '',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: []
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
