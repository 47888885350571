/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

} */
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import {  ViewChild, ElementRef } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class FormInput {
  pid: any;
  lendername: any;
  image: any;
  link: any;
  lid: any;

}

export class formInputT1 {
  id: any;
  teacherName: any;
  name: any;
  teacherId: any;
 

}


export class formInputT2 {
  id: any;
  teacherName: any;
  name: any;
  timeId: any;
 

}

export class formInputT3 {
  id: any;
  teacherName: any;
  name: any;
  daysId: any;
 

}


@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {

  private filesControl = new FormControl(null, FileUploadValidators.filesLimit(1));
  

  @ViewChild('modalProject', {
    static: false
  }) modal: any;


 /* @ViewChild('modalTeacher', {
    static: false
  }) modal: any;*/

   dtExportButtonOptions: any = {};
  /*dtExportButtonOptions = {
    dom: 'Bfrtip',
  };*/
  formInputT : formInputT1;
  formInputTime : formInputT2;
  formInputDays : formInputT3;



  formInputTeacher : any={};
  formInput: any={};
  formInput2: FormInput;
  form: any;
  subcatid: any;
  type: any = 'add';
  public isSubmit: boolean;
  formdata: any = {};
  loading = false;
  buttonloading = false;
  statusloading = [];
  change: any = {};
  tabledata: any = {};
  teacherdata: any = {};
  classtimedata: any = {};
  classdaysdata: any = {};
  editdata: any = {};
  countdata: any = {};
  planList: any = {};
  plan_id: any ;
  userid: any ;
  fromDate: NgbDateStruct;
  public status: any = [{
    value: '0',
    label: 'Inactive'
  },
  {
    value: '1',
    label: 'Active'
  }
  ];
  public options: any = [{
      value: '0',
      label: 'Alabama'
    },
    {
      value: '1',
      label: 'Wyoming'
    },
    {
      value: '2',
      label: 'Coming'
    },
    {
      value: '3',
      label: 'Henry Die'
    },
    {
      value: '4',
      label: 'John Doe'
    }
  ];
 
  selectedOption = '3';
  public addform = new FormGroup({
    files: this.filesControl
  });
  public uploadedFiles: Array < File > = [];
  public uploadedFiles2: Array < File > = [];
  selectedsite : any; 
  siteoptions: any[];
  constructor(public global: GlobalService, public router: Router) {
    this.isSubmit = false;

  }
  addagent(aa) {
    console.log(this.filesControl.value);
    console.log(this.formInput);
    console.log(this.uploadedFiles);

  }
  ngOnInit() {
    //this.filesControl.enable();
    //     this.filesControl.valueChanges.subscribe(() => {
    //       console.log(this.filesControl);
    //       if(this.filesControl.value.length == 0) {
    //          this.filesControl.enable();
    //         } else{
    //             this.filesControl.disable();
    //            }
    //     }

    // );

    this.formInputT = {
      id: '',
      teacherName: '',
      name: '',
      teacherId: ''
  
    };

    this.formInputTime = {
      id: '',
      teacherName: '',
      name: '',
      timeId: ''
  
    };

    this.formInputDays = {
      id: '',
      teacherName: '',
      name: '',
      daysId: ''
  
    };


    

    this.formInput = {
      pid: '',
      lendername: '',
      image: '',
      link: '',
      lid: '',
    };
    this.formInput2 = {
      pid: '',
      lendername: '',
      image: '',
      link: '',
      lid: '',

    };
    

    this.dtExportButtonOptions = {
     "processing": true,
     columnDefs: [
       { orderable: false, targets: [0,5,6,7,8,9,10,11] }
     ],
     "serverSide": true,
     "ajax": this.global.server2 + 'students/getstudents_sales.php'
    };
    this.lenders('yes');
    this.teacherlist();
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
  }
  openupdate(index) {
    //this.editdata = this.tabledata[index];
    this.formInput2 = {
      pid: this.tabledata[index].pid,
      lendername: this.tabledata[index].name,
      image: this.tabledata[index].img,
      link: this.tabledata[index].url,
      lid: this.tabledata[index].id,
    };
    console.log(this.editdata);
    this.type = 'update';
    this.modal.show();
  }

  lenders(loading) {

    if(loading=='yes'){
      this.loading = true;
     }
    // this.formdata = this.formInput;
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    // const headers = new Headers();

    this.formdata.sid = this.selectedsite;
    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


   /* this.global.http.get(this.global.server2 + 'students/getstudents_sales.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.tabledata = data['data'];
          this.countdata = data['count'];
          this.siteoptions = [];
          let i;
          console.log(this.tabledata);
          // for (i = 0; i < data['site'].length; i++) {
          //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
          //     this.selectedsite = data['site'][0].id;

          //   }
          // }
          
          this.loading = false;

        } else {
          //   alert();
          this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
*/

      this.global.http.get(this.global.server2 + 'classes/getPlans.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.planList = data['data']
          console.log(this.tabledata);
          
        //  this.loading = false;

        } else {
          //   alert();
          //this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });



      
  }


  
  teacherlist() {

    

    this.global.http.get(this.global.server2 + 'students/teacher_list.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.teacherdata = data['data'];
         

        } 
      }, error => {
        console.log(error);
        //this.loading = false;

      });

      this.global.http.get(this.global.server2 + 'students/classtime_list.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.classtimedata = data['data'];
         

        } 
      }, error => {
        console.log(error);
        //this.loading = false;

      });


      this.global.http.get(this.global.server2 + 'students/class_days_list.php')
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == true) {
          this.classdaysdata = data['data'];
         

        } 
      }, error => {
        console.log(error);
        //this.loading = false;

      });



  }


  democomplete(id)
 {
    this.change.id=id;
    console.log(id)
;
    /* this.buttonloading = true;
    this.formdata.id = id;
    console.log(this.formdata); */
    // const token = localStorage.getItem('token');
    // const headers = new Headers();

    const postData = this.change;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'students/democomplete.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          //this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Demo completed successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }


 
 
 
  democonfirm(id)
 {
   // console.log("hello delete");
    //console.log(id)

    console.log('id is '+id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'User completed their demo ?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.democomplete(id);

      }
    });
  }




  addlender(form: any) {
    console.log(this.formInput);
    console.log(form.valid);
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }
    if (!this.uploadedFiles[0]) {
      this.isSubmit = true;
alert('Select the image');
      return;
    }



    this.buttonloading = true;
    // this.formdata = this.formInput;
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    // const headers = new Headers();
    this.formInput.sid = this.selectedsite ;
    const postData = this.formInput;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Accept: 'application/json',
    //     Authorization: token
    //   })
    // };
    let formData: FormData = new FormData();
    console.log(this.uploadedFiles);

    const file: File = this.uploadedFiles[0];
    formData.append('file', file, file.name);
    console.log(this.formInput);
    console.log(this.formInput.toString());
    formData.append('data', JSON.stringify(this.formInput));
    formData.append('name', 'ss');



    this.global.http.post(this.global.server + 'category/addnew.php', formData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles = [];
          this.global.addToast({
            title: 'Lender added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }

adduser()
{
  this.router.navigate(['adduser']);
}

editpage(id)
{
  this.router.navigate(['editcategory/'+id]);
}
  
updatelender(form: any) {
    console.log(this.formInput);
    console.log(form.valid);
    if (!form.valid) {
      this.isSubmit = true;

      return;
    }




    this.buttonloading = true;
    this.formdata = this.formInput;
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    const headers = new Headers();

    // const postData = this.formInput;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Accept: 'application/json',
    //     Authorization: token
    //   })
    // };
    let formData: FormData = new FormData();
    console.log(this.uploadedFiles2);
    if (this.uploadedFiles2[0]) {


      const file: File = this.uploadedFiles2[0];
      formData.append('file', file, file.name);
    }
    console.log(this.formInput);
    console.log(this.formInput.toString());
   // formData.append('data', JSON.stringify(this.formInput2));
    formData.append('data', JSON.stringify(this.formInput));

    //this.global.http.post(this.global.server + 'category/updatestudent1.php', formData, {})
    this.global.http.post(this.global.server2 + 'students/updatestudent1.php', formData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.uploadedFiles2 = [];
          this.global.addToast({
            title: 'Category added successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }



  addclass(){
    console.log(JSON.stringify(this.formInput));
    const token = localStorage.getItem('token');
    const postData = this.formInput;
    console.log(postData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'students/addstudents.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
        this.modal.hide();
        this.ngOnInit();
        this.global.addToast({
          title: 'Student added successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })
        this.buttonloading = false;
      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['message2'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });
  }


  
  updateTeacher(){
   // console.log(JSON.stringify(this.formInputTeacher));
    const token = localStorage.getItem('token');
    var postData = this.formInputT;
    //postData['sid'] = this.formInputT.id;
    console.log(postData);
    //console.log(this.formInputT);

    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'students/updatetecher.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
        this.modal.hide('modalTeacher');
        this.ngOnInit();
        this.global.addToast({
          title: 'Teacher updated successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })
        this.buttonloading = false;
      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['message2'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });
  }


  updateStudentClassTime(){

    // console.log(JSON.stringify(this.formInputTeacher));
    const token = localStorage.getItem('token');
    var postData = this.formInputTime;
    //postData['sid'] = this.formInputT.id;
    console.log(postData);
    //console.log(this.formInputT);

    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'students/updateStudentClassTime.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
        this.modal.hide('modalClassTime');
        this.ngOnInit();
        this.global.addToast({
          title: 'Class time updated successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })
        this.buttonloading = false;
      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['message2'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });


  }



  
  updateStudentClassDays(){

    // console.log(JSON.stringify(this.formInputTeacher));
    const token = localStorage.getItem('token');
    var postData = this.formInputDays;
    //postData['sid'] = this.formInputT.id;
    console.log(postData);
    //console.log(this.formInputT);

    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token
      })
    };
    this.global.http.post(this.global.server2 + 'students/updateStudentClassDays.php',postData , httpOptions)
    .subscribe(data => {
      console.log(data);
      if (data['status'] == 'success') {
        this.modal.hide('modalClassTime');
        this.ngOnInit();
        this.global.addToast({
          title: 'Class time updated successfully',
          msg: ' ',
          timeout: 8000,
          theme: 'default',
          position: 'top-right',
          type: 'success'
        })
        this.buttonloading = false;
      }
      else{
        this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['message2'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
      }
    },error => {
      console.log(error);
    });


  }



  delete(id) {
    this.change.id=id;
    console.log(id);
    /* this.buttonloading = true;
    this.formdata.id = id;
    console.log(this.formdata); */
    // const token = localStorage.getItem('token');
    // const headers = new Headers();

    const postData = this.change;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server2 + 'students/deletestudents.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          //this.modal.hide();
          // tslint:disable-next-line
          this.ngOnInit();
          this.global.addToast({
            title: 'Deleted successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }




  deleteconfirm(id) {
    console.log("hello delete");
    console.log(id);
    console.log('id is '+id);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        this.delete(id);
      }
    });
  }


  
  studentlogin(id) {
    console.log("hello delete");
    console.log(id);
    console.log('id is '+id);
    Swal.fire({
      title: 'Are you sure want?',
      text: 'You can login in student account!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        // Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {
        window.open('https://take5music.in/loginByadmin-'+id,'_blank');

      }
    });
  }



viewuser(id)
{
  this.router.navigate(['viewuser/'+id]);
  return;
  this.loading= true;
  this.formdata.id=id;

  this.subcatid=JSON.stringify(this.formdata);

  const postData = this.subcatid;
  this.global.http.post(this.global.server + 'user/view.php', postData, {})
  .subscribe(data => {
    console.log(data);
    // tslint:disable-next-line: triple-equals
    if (data['status'] == 'success') {
      this.tabledata = data['records'];
      let i;
      console.log(this.tabledata);
      // for (i = 0; i < data['site'].length; i++) {
      //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
      //   if(!this.selectedsite || this.selectedsite== null){
      //     this.selectedsite = data['site'][0].id;

      //   }
      // }
      
this.loading=false;
    } else {
      //   alert();
      

    }
  }, error => {
    console.log(error);
    this.loading = false;

  });
}

  chnagestatus(id, status) {
    this.statusloading[id] = 1;
    this.formdata.id = id;
    console.log(this.formdata.id);
    this.formdata.status = status;
    this.formdata.type = 'category';
    console.log(this.formdata);
    // const token = localStorage.getItem('token');
    // const headers = new Headers();

    const postData = this.formdata;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };


    this.global.http.post(this.global.server + 'user/userpanel/update.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
         // this.ngOnInit();
          this.lenders('no');
          this.global.addToast({
            title: 'Updated successfully',
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })
          this.statusloading[id] = 0;

        } else {
          this.statusloading[id] = 0;

          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  }

  updateStudentPayment() {
    console.log(this.plan_id);
    console.log(this.userid);
    console.log(this.fromDate);
     const token = localStorage.getItem('token');
     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         Authorization: token
       })
     };

const postData={
	plan_id:this.plan_id,
	user_id:this.userid,
	date:this.fromDate,
	}
	const date=this.fromDate.year+"-"+this.fromDate.month+"-"+this.fromDate.day;
	console.log(date);
    this.global.http.post(this.global.server3 + '/adminPayment',  postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modal.hide();
          // tslint:disable-next-line
         // this.ngOnInit();
          this.lenders('no');
          this.global.addToast({
            title: data['message'],
            msg: ' ',
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'success'
          })

        } else {

          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
 
 
 
  }
  
    onDateChange(date: NgbDateStruct) {
      this.fromDate = date;
    console.log(this.fromDate);
    }

   


}
