import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-studentlogin',
  templateUrl: './studentlogin.component.html',
  styleUrls: ['./studentlogin.component.scss']
})
export class StudentloginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
