import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
export class FormInput {
  username: any;
  password: any;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isSubmit: boolean;
  formInput: FormInput;
  formdata: any = {};
  loading = false;
  invalid = false;
  constructor(public global: GlobalService, public router: Router) { }

  ngOnInit() {
    localStorage.removeItem("token");
    this.global.storage.remove('userdata');
    this.global.storage.remove('userid');
    this.formInput = {
      username:  '',
      password:  '',
    };
    // this.global.storage.get('userdata').then((data) => {
    //   console.log('this is user data');
    //   console.log(data);
      
    //     });
  }
  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
  }

  login(form: any) {
    this.invalid = false;
    this.loading = true;

    if (!form.valid) {
      this.isSubmit = true;
      return;

    }
    this.formdata = this.formInput;
    console.log(this.formdata);

    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json' ); 
    const postData =  this.formdata;
    this.global.http.post(this.global.server2+'/login_sales.php', postData, { observe: 'response'} )
    .subscribe(data => {
      console.log(data.body);
      // tslint:disable-next-line: triple-equals
      if (data.body['status']== true){
        data.body['records'][0].role='admin';
       
        this.global.storage.set('userdata', data.body['records'][0]);
        this.global.userdata = data.body['records'][0];
        this.global.storage.set('userid', data.body['records'][0].id);
        localStorage.setItem('token', data.body['token']);
        // this.global.socket(data.body['records'][0].id);
        this.router.navigate(['student']);
        this.loading = false;


      } else{
     //   alert();
     this.invalid = true;
     this.loading = false;

      }
     }, error => {
      console.log(error);
      //this.loading = false;

    });
   }
}
