/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demofullcalendar',
  templateUrl: './demofullcalendar.component.html',
  styleUrls: ['./demofullcalendar.component.scss']
})
export class DemofullcalendarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
 */
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { PlacementArray } from 'positioning';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import {FormControl, FormGroup} from '@angular/forms';
 
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { delay } from 'rxjs/operators';
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;



const now = new Date();

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-demofullcalendar',
  templateUrl: './demofullcalendar.component.html',
  styleUrls: ['./demofullcalendar.component.scss']
})
export class DemofullcalendarComponent implements OnInit {
  @Input() eventSnapSize: number;
  @Input() teacherid: any;

  /**
   * The placement of the event tooltip
   */
  @Input() tooltipPlacement: PlacementArray = 'auto';

  /**
   * A custom template to use for the event tooltips
   */
  @Input() tooltipTemplate: TemplateRef<any>;

  /**
   * Whether to append tooltips to the body or next to the trigger element
   */
  @Input() tooltipAppendToBody: boolean = true;

  /**
   * The delay in milliseconds before the tooltip should be displayed. If not provided the tooltip
   * will be displayed immediately.
   */
  @Input() tooltipDelay: number | null = null;

  /**
   * A custom template to use for day view events
   */
   @Input() eventTemplate: TemplateRef<any>;

   /**
    * A custom template to use for event titles
    */
   @Input() eventTitleTemplate: TemplateRef<any>;
 
   /**
    * A custom template to use for event actions
    */
   @Input() eventActionsTemplate: TemplateRef<any>;
 

  @ViewChild('modalProject', {static: false}) modalProject: any;
  @ViewChild('modalProject', {static: false}) modalProject1: any;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  dayStartHour = "8 AM";
  dayEndHour = "8 PM";
type: any='none';
formInput4: any = {};
collapse : any = {};


  public model: any;
  modelCustomDay: any;
  tableloading1: any;
  tableloading2: any;
  filter: any;
  
  instruments:any={};
  teacher:any={};
  selectedtecaher:any={};
  classes:any={};
  days1:any=[];
  
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  disabled = true;
  searchstring : any;
all = false;
  @Input() testRangeDate: Date;

  modelPopup: NgbDateStruct;
  public date: {year: number, month: number};

  modelDisabled: NgbDateStruct = {
    year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()
  };


buttonloading = false;
getinstruments:any={};

days:any=[
  {
    value: 'Monday',
    label: 'Monday,Thursday'
  },
  {
    value: 'Tuesday',
    label: 'Tuesday,Friday'
  },
  {
    value: 'Wednesday',
    label: 'Wednesday,Saturday'
  },
 
  
];
;
selecteddays:any={};
duration:any={};
data:any={};
slots:any={};

teacherdata:any={};
formInput5 : any ={};
error : any;
id : any;
type1: any='add';
loading : any;
 latest_date :any=this.datepipe.transform(Date(), 'yyyy-MM-ddTHH:mm'); 
public isSubmit: boolean;
  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
 
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    // {
    //   start: subDays(startOfDay(new Date()), 1),
    //   end: addDays(new Date(), 1),
    //   title: 'A 3 day event',
    //   color: colors.red,
    //   actions: this.actions,
    //   allDay: true,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
    // {
    //   start: startOfDay(new Date()),
    //   title: 'An event with no end date',
    //   color: colors.yellow,
    //   actions: this.actions,
    // },
    // {
    //   start: subDays(endOfMonth(new Date()), 3),
    //   end: addDays(endOfMonth(new Date()), 3),
    //   title: 'A long event that spans 2 months',
    //   color: colors.blue,
    //   allDay: true,
    // },
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: colors.yellow,
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true, 
    //   },
    //   draggable: true,
    // },
  ];

  activeDayIsOpen: boolean = true;
  addoffer()
  {}
CalendarDayView(event)
{
  alert();
  console.log(event);
  console.log("It works");
  this.ngOnInit();
}
  constructor(public datepipe: DatePipe,public global: GlobalService,private modal: NgbModal,public parserFormatter: NgbDateParserFormatter, public calendar: NgbCalendar,) {
    let currdate = new Date();
    console.log(currdate);
    this.latest_date =this.datepipe.transform(currdate, 'yyyy-MM-ddThh:mm');
    this.formInput4.ctime = this.latest_date; 
    this.isSubmit = false;
        this.formInput4.ctime='2021-04-13T18:31'; 
        this.formInput4.ctime=this.latest_date;

  }
  getinstrument(){
    console.log("getinstrument");
    const token = localStorage.getItem('token');
    const loginUserId = localStorage.getItem('loginUserId');
    const postData = 'getinstruments';
    const httpOptions = {
      headers: new HttpHeaders({
       // 'Content-Type': 'application/json',
        Authorization: token
      })
    };
    console.log(token);
    console.log(httpOptions);
    this.global.http.post(this.global.server2 + 'instruments/getinstruments.php',postData,)
    .subscribe(data =>{
      console.log(data);
      if (data['status'] == true) {
        console.log("dataensdsfster")
        this.getinstruments = data['data'];
        console.log("oiuioiuy");
        console.log(this.getinstruments);
      } else {
        console.log("ADASdadadad");
        console.log(data['status']);
      }
    }, error => {
      console.log(error);
    });
  }
  getdays(id){
   

    this.tableloading1= true;
    
    //console.log(this.formdata);
    const token = localStorage.getItem('token');
   

    const headers = new Headers();
 
   
    const postData = {id:id};
   
    this.global.http.post(this.global.server2 + 'classes/getdays.php', postData, {})
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.days1=[];
        
          for (let i = 0; i < data['days'].length; i++) { 
            console.log(data['days'][i]);
            this.days1.push({'label': data['days'][i].days+' '+data['days'][i].time, 'value':data['days'][i]});
  
          }
          console.log(this.days); 
          //this.tname=this.tabledata['name'];
          this.tableloading1 = false;
 //alert('bvfui');
        } else {
          //   alert();
          this.tableloading1 = false;
 
        }
      }, error => {
        console.log(error);
        //this.loading = false;
 
      });
  }
  getclasses(){
    this.loading=true;
    console.log("getinstrument");
    const token = localStorage.getItem('token');
   // alert(this.teacherid);
    if(this.teacherid!=null){
      //alert(this.teacherid);
    var formdata:any={};
     formdata.id = this.teacherid;
      var postData = formdata;
    
      //alert('acuaicgiua');
      this.events=[];
      this.global.http.post(this.global.server2 + 'classes/classlist.php',postData).subscribe(data =>{ 
        console.log(data);
        if (data['status'] == true) {
          console.log("dataensdsfster")
          
          console.log("oiuioiuy");
          console.log(data['data']);
          this.data=data['data'];
        
          //alert(data['data'][0].teacher);
          this.id=data['data'][0].teacher;
          for (var i = 0; i < this.data.length; i++) { 
            this.events.push(
              {
                id: this.data[i].id,
                start: new Date(this.data[i].start),
                end: new Date(this.data[i].end),
                cssClass: 'calclass',
                title: i.toString(),
              
                color: colors.blue,
               // actions: this.actions,
                resizable: {
                  beforeStart: false,
                  afterEnd: false
                },
                draggable: false,
              }, 
            ); 
            
          }
          console.log("etcetssssssssssssssssssssssssssssssssssssssssssssssssssssssssss");
          console.log(this.events);
          this.loading=false;
          
  
          // this.events = [
          //   ...this.events,
          //   {
          //     title: 'New event',
          //     start: startOfDay(new Date()),
          //     end: endOfDay(new Date()),
          //     color: colors.red,
          //     draggable: true,
          //     resizable: {
          //       beforeStart: true,
          //       afterEnd: true,
          //     },
          //   },
          // ];
        } else {
          console.log("ADASdadadad")
          console.log(data['status']);
        }
      }, error => {
        console.log(error);
      });}
      else{
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token
          })
        };
        const loginUserId = localStorage.getItem('loginUserId');
         postData = {id: loginUserId}; //'getinstruments';
         this.global.http.post(this.global.server2 + 'classes/classlist.php',postData,httpOptions).subscribe(data =>{ 
          console.log(data);
          if (data['status'] == true) {
            console.log("dataensdsfster")
            
            console.log("oiuioiuy");
            console.log(data['data']);
            this.data=data['data'];
            this.id=this.data[0].teacher;
           // alert(data['data'][0].teacher);
          this.id=data['data'][0].teacher;

            for (var i = 0; i < this.data.length; i++) { 

              this.events.push(
                {
                  id: this.data[i].id,
                  start: new Date(this.data[i].start),
                  end: new Date(this.data[i].end),
                  cssClass: 'calclass',
                  title: i.toString(),
                
                  color: colors.blue,
                 // actions: this.actions,
                  resizable: {
                    beforeStart: false,
                    afterEnd: false
                  },
                  draggable: false,
                },
              ); 
              
            }
            this.getdays(this.id);
            console.log("etcetssssssssssssssssssssssssssssssssssssssssssssssssssssssssss");
            console.log(this.events);
            this.loading=false;
    
            // this.events = [
            //   ...this.events,
            //   {
            //     title: 'New event',
            //     start: startOfDay(new Date()),
            //     end: endOfDay(new Date()),
            //     color: colors.red,
            //     draggable: true,
            //     resizable: {
            //       beforeStart: true,
            //       afterEnd: true,
            //     },
            //   },
            // ];
          } else {
            console.log("ADASdadadad")
            console.log(data['status']);
          }
        }, error => {
          console.log(error);
        });
      }
    
  
    
    
    
    
  }
  deleteclass(form,condition){
   console.log(this.formInput4);
    this.formInput4.sid=this.id;
    
    this.formInput4.condition=condition;
    if(this.formInput4.selecteddays==null||this.formInput4.condition==null||this.id==null){
      alert('fill all fields');
      return;
    }
    console.log(this.formInput4);
    const token = localStorage.getItem('token');
    const headers = new Headers();

    //this.formdata.sid = this.selectedsite;
    const postData = this.formInput4;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Authorization: token
    //   })
    // };

    
    this.global.http.post(this.global.server2 + 'classes/deleteclasses.php', postData, {}) 
      .subscribe(data => {
        console.log(data); 
        // tslint:disable-next-line: triple-equals
        if (data['success'] == true) {
          console.log('hello');
          if(this.formInput4.condition=='confirm'){
            this.modalProject1.hide(); 
 this.type1='add';
           this.ngOnInit();
  //this.formInput4=null;
 

          }
          if(this.formInput4.condition!='confirm'){
            

          
          this.classes = data['records']; 
          if(data['teacher'][0]!=null){
          this.selectedtecaher = data['teacher'][0]; 
          }
          }
          
          this.type1='confirm';
          
         // this.countdata = data['count'];
          //this.formdata.mobile=this.countdata.mobile;
        //  this.formdata.email=this.countdata.email;
          console.log(this.teacherdata);
          //this.sname=this.teacherdata['name'];
         // this.siteoptions = [];
          let i;
          // for (i = 0; i < data['site'].length; i++) {
          //   this.siteoptions.push({'label': data['site'][i].url, 'value':data['site'][i].id});
          //   if(!this.selectedsite || this.selectedsite== null){
          //     this.selectedsite = data['site'][0].id;

          //   }
          // }
          
          // this.loading = false;

        } else {
          //   alert();
          // this.loading = false;

        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });



  } 


  addclass(form: any) {
    if (this.formInput4.endtime==null||this.formInput4.time==null||this.formInput4.iid==null||this.formInput4.ctitle==null||this.formInput4.endtime==null||this.formInput4.selecteddays==null) {
      this.isSubmit = true;

      return; 
    }
    console.log(this.formInput4.ctime);
   
    if(this.teacherid!=null){
      //alert(this.teacherid);
    
    this.formInput4.id = this.teacherid;}
    const token = localStorage.getItem('token');
    



    this.buttonloading = true;
    // this.formdata = this.formInput;
    console.log(this.formInput4);
    
   // this.formInput.sid = this.selectedsite ;
    const postData = this.formInput4;
   
    let formData: FormData = new FormData();
  
    formData.append('data', JSON.stringify(this.formInput4));
    formData.append('name', 'ss');

    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        Authorization: token
      })
    };

    this.global.http.post(this.global.server2 + 'classes/addclasses.php', postData,httpOptions)
      .subscribe(data => {
        console.log(data);
        // tslint:disable-next-line: triple-equals
        if (data['status'] == 'success') {
          this.modalProject.hide();
          // tslint:disable-next-line
          this.ngOnInit();
      // this.formInput4=null;
       this.global.addToast({
        title: 'Classes added successfully',
        msg: ' ',
        timeout: 8000,
        theme: 'default',
        position: 'top-right',
        type: 'success'
      })
          this.buttonloading = false;

        } else {
          //   alert();
          // tslint:disable-next-line
          this.buttonloading = false;
          this.global.addToast({
            title: 'failed',
            msg: data['statusmessage'],
            timeout: 8000,
            theme: 'default',
            position: 'top-right',
            type: 'error'
          })
        }
      }, error => {
        console.log(error);
        //this.loading = false;

      });
  } 
  // ngOnInit(): void {
  //   //this.CalendarDayView();
  //   this.getinstrument();
  //   this.duration=[
  //     {time:60},{time:90},{time:120},{time:180}
  //   ]
  //   this.slots=[
  //     {tslots:1},
  //     {tslots:2},
  //     {tslots:3},
  //     {tslots:4},
  //     {tslots:5},
  //   ]
  //   throw new Error('Method not implemented.');
    
  // }
  ngOnInit(){
    //alert(this.teacherid);
    
        this.getinstrument();
        this.getclasses();
        this.type='update';
        
        this.formInput4.ctime=this.latest_date;
       
      
    this.duration=[
      {time:60},{time:90},{time:120},{time:180}
    ]
    this.slots=[
      {tslots:1},
      {tslots:2},
      {tslots:3},
      {tslots:4},
      {tslots:5},
    ]
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    console.log("Update Model");
    this.type="update";
    this.modalProject.show();
   console.log(event);
    this.formInput4 = event;
    console.log(this.formInput4.id);
    console.log(this.formInput4);
    console.log(this.formInput4.title);
    console.log(this.formInput4['start'])
    //this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });
  }
  HourEvent(action, event) {
     delay(300000);
    let newDate = new Date(event['date']);
    console.log(event['date']);
     this.latest_date =this.datepipe.transform(event['date'], 'yyyy-MM-ddTHH:mm'); 
    
     this.formInput4.ctime=this.latest_date;
     this.latest_date =this.datepipe.transform(event['date'], 'dd-MM-yy hh:mm a');
     console.log(this.formInput4.ctime);
    
      this.type="add";
    this.modalProject.show();
    
     
  
    console.log(newDate);
  
   
    console.log(this.latest_date);
    
    //alert('added');
    console.log(this.formInput4.ctime);
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}